<template>
  <v-col cols="12">
    <v-card :disabled="disabled">
      <v-card-title>Parametrização de orçamento</v-card-title>
      <v-card-text>
        <v-row>
          <input-v v-for="(col, index) of cols" class="px-4 py-n2"
            v-model="parametrizacao[col.key]"
            :key="index"
            :label="col" :colSize="col.colSize" :opts="opts" :valid="validation.get(col.key)"
            @input="setValueContrapartida(col, $event)">
          </input-v>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-content-end">
        <v-btn depressed dark color="primary" class="mx-3 mb-2 mt-n5" @click="doSaveParam()">Salvar
          parametrização</v-btn>
      </v-card-actions>
    </v-card>
    <v-card class="mt-2" :disabled="!parametrizacao.id || disabled ? true : false">
      <v-card-title>Parcelas</v-card-title>
      <v-card-text>
        <MasterDetail ref="masterDefatilParcelas" v-if="parametrizacao.id" :hasNewButton="false" :canDelete="false" :canEdit="false"
          :cols="colsParcelas" :opts="opts" :customResource="customResourceParcelas" :contextOptions="contextOptions">
          <FormModal maxWidth="650px" :opened.sync="modalEditParcelas" :value.sync="modalValue" :cols="colsParcelas" :opts="opts"
            @save="doSaveParcelas">
          </FormModal>
        </MasterDetail>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  props: {
    disabled: Boolean
  },
  components: {
    'input-v': () => import("@/components/input-v.vue"),
    MasterDetail: () => import("@/components/master-detail.vue"),
    FormModal: () => import("@/components/form-modal.vue"),
  },
  // mixins: [],
  // directives: {},
  computed: {
    ...mapGetters(['clientId']),
    projetoId() {
      return this.$route.query.projetoId;
    },
    contextOptions() {
      const that = this;
      const options = [
        {
          name: "Editar",
          show: true,
          cb(row) {
            that.modalEditParcelas = true;
            that.modalValue = row;
          }
        }
      ];
      return options.filter(({ show }) => show)
    },
    cols() {
      return [
        {
          key: 'quantidade_parcelas',
          name: 'Qtd. parcelas',
          type: this.$fieldTypes.NUMBER,
          rules: [{ rule: 'required' }],
          colSize: 4,
        },
        {
          key: 'percentual_financiado',
          name: 'Financiado (%)',
          type: this.$fieldTypes.DOUBLE,
          rules: [{ rule: 'required' }],
          colSize: 4,
        },
        {
          key: 'percentual_contrapartida',
          name: 'Contrapartida (%)',
          type: this.$fieldTypes.DOUBLE,
          colSize: 4,
        },
        {
          key: 'percentual_comprovacao',
          name: 'Comprovação (%)',
          type: this.$fieldTypes.DOUBLE,
          colSize: 4,
        },
        {
          key: 'valor_total_projeto',
          name: 'Valor total do projeto',
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: 'required' }],
          colSize: 8,
        },
      ]
    },
    colsParcelas() {
      return [
        {
          key: 'numero_parcela',
          name: 'Nº da parcela',
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 4,
        },
        {
          key: 'data_inicio',
          name: 'Data inicio',
          type: this.$fieldTypes.DATE,
          colSize: 4,
        },
        {
          key: 'data_fim',
          name: 'Data fim',
          type: this.$fieldTypes.DATE,
          colSize: 4,
        },
        {
          key: 'valor_contrapartida',
          name: 'Valor total contrapartida',
          type: this.$fieldTypes.MONEY,
          colSize: 6,
        },
        {
          key: 'valor_financiado',
          name: 'Valor total financiado',
          type: this.$fieldTypes.MONEY,
          colSize: 6,
        },
      ]
    },
    customResourceParametrizacao() {
      return this.apiResource(`v1/captacao/${this.clientId}/orcamentos/${this.projetoId}/parametrizacao`)
    },
    resourceUrlParcelas() {
      return this.apiResource(`v1/captacao/${this.clientId}/orcamentos/${this.parametrizacao.id}/parcelas`)
    },
    resourceUrlParcelasDelete() {
      return this.apiResource(`v1/captacao/${this.clientId}/orcamentos`)
    },
    customResourceParcelas() {
      const resource = this.resourceUrlParcelas;
      const that = this;
      return {
        ...resource,
        get() {
          return resource.get().then((response) => {
            return that.parcelas = response
          })
        },
        save(...args) {
          let dados = {
            ...args[0],
            projeto_id: that.projetoId,
            empresa_id: that.clientId
          }
          return resource.save(dados, dados.id).then((response) => { return response })
        }
      }
    }
  },
  //filters: {},
  data() {
    return {
      parametrizacao: {
        id: null,
        percentual_contrapartida: 0,
        percentual_financiado: 0,
        percentual_comprovacao: 0,
        valor_total_projeto: '',
        quantidade_parcelas: 0,
      },
      parcelas: [],
      opts: {},
      valor_atualizado: 0,
      validation: new Map(),
      modalEditParcelas: false,
      modalValue: {
        id: null,
        numero_parcela: 0,
        data_inicio: '',
        data_fim: '',
        valor_contrapartida: '',
        valor_financiado: '',
        projeto_id: this.projetoId,
        empresa_id: this.clientId,
      }
    }
  },
  created() {
    this.getParametrizacao();
  },
  // mounted() {},
  // updated() {},
  // destroyed() {},
  methods: {
    getParametrizacao() {
      let resource = this.apiResource(`v1/captacao/${this.clientId}/orcamentos/${this.projetoId}/parametrizacao`);
      const that = this;
      resource.get().then((response) => {
        that.parametrizacao = response;
        return response
      })
    },
    doSaveParam() {
      const resource = this.customResourceParametrizacao;
      const that = this;
      this.parametrizacao['projeto_id'] = this.projetoId;
      this.parametrizacao['empresa_id'] = this.clientId;
      resource.save({ ...that.parametrizacao }, that.parametrizacao.id).then((result) => {
        return that.parametrizacao = result;
      })
    },
    setValueContrapartida(target, value) {
      if (target.key !== 'percentual_financiado') return
      this.parametrizacao.percentual_contrapartida = Number.parseFloat(100 - value);
    },
    doSaveParcelas(dados) {
      const valorTotalProjeto = Number.parseFloat(this.parametrizacao.valor_total_projeto);
      const valorFinanciadoParcela = Number.parseFloat(dados.valor_financiado);
      const valorContrapartidaParcela = Number.parseFloat(dados.valor_contrapartida);

      const totalDados = Number.parseFloat(valorFinanciadoParcela + valorContrapartidaParcela);
      if (totalDados > valorTotalProjeto) {
        this.$notify({
          group: "geral",
          duration: 15000,
          title: "Atenção !",
          type: "warning",
          text: 'Valor total das parcelas não deve ultrapassar o valor total do projeto!',
        })
      }

      const resource = this.resourceUrlParcelas;
      dados['projeto_id'] = this.projetoId;
      dados['empresa_id'] = this.clientId;
      dados['parametrizacao_id'] = this.parametrizacao.id;
      resource.save({ ...dados }, dados.id).then((result) => {
        this.$refs.masterDefatilParcelas.doLoad();
        this.modalEditParcelas = false;
        return result;
      })
    }
  },
  // watch: {},
}
</script>

<style></style>